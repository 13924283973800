import { queriesBuyerString } from './strings'
import { Amplify } from './amplify'
import { removeData, textWithoutEspecials } from './functions'
import CryptoJS from 'crypto-js'
const amplify = new Amplify()

export const handleSessionFailures = async (
  responsePromise,
  navigate,
  setSessionData,
  handleShowInactiveSessionModal
) => {
  const fails = [queriesBuyerString.inactive, queriesBuyerString.expired]
  const response = await responsePromise
  const res = await response.json()
  if (!res.response && res.data && res.data.code && res.data.code.length < 3) {
    return res
  }
  if (res.response === false &&
    ((res.data && res.data.code && fails.includes(res.data.code)) || !('body' in res))) {
    removeData({ navigate, setSessionData, handleShowInactiveSessionModal })
  }
  return res
}

export const contactForm = async ({
  userId,
  pqrsId = 1,
  content,
  statusId = 1,
  documentPath,
  authTk
}) => {
  let token = await amplify.getToken()
  const isToken = amplify.validateToken(token)
  if (!isToken) {
    amplify.cleanToken()
    await amplify.sign()
    token = await amplify.getToken()
  }
  if (!content) {
    throw new Error(queriesBuyerString.contentNull)
  } else if (typeof content !== 'string') {
    throw new Error(queriesBuyerString.notStringValues)
  }
  const headers = {
    'Content-Type': 'application/json',
    TokenCatalogo: token
  }
  let path = ''
  if (userId) {
    path = `${process.env.REACT_APP_HOST}/request`
    headers.Authorization = `Bearer ${authTk}`
  } else {
    path = `${process.env.REACT_APP_HOST}/request/dataTreatment`
  }
  const bodyJson = {
    userId,
    pqrsId,
    content,
    statusId
  }
  if (documentPath !== null) {
    bodyJson.documentPath = documentPath
  }
  try {
    const res = await fetch(path, {
      method: 'POST',
      body: JSON.stringify(bodyJson),
      headers
    })
    if (!res.ok) {
      throw new Error(queriesBuyerString.authenticationError)
    }
    const data = await res.json()
    return data
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const contactForm2 = async ({
  pqrsId = 1,
  content,
  statusId = 1,
  authContext
}) => {
  if (!content) {
    throw new Error(queriesBuyerString.contentNull)
  } else if (typeof content !== 'string') {
    throw new Error(queriesBuyerString.notStringValues)
  }
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { buyerId, authTk } = sessionData
  const bodyJson = {
    buyerId,
    pqrsId,
    content,
    statusId
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const res = await fetch(`${process.env.REACT_APP_HOST}/request`, {
      method: 'POST',
      body: JSON.stringify(bodyJson),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTk}`,
        TokenCatalogo: token
      }
    })
    if (!res.ok) {
      throw new Error(queriesBuyerString.authenticationError)
    }
    const data = await handleSessionFailures(
      res,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const identDocuments = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/ident-document`,
      {
        headers: {
          TokenCatalogo: token
        }
      }
    )

    const data = await response.json()
    const dataList = {
      documents: data.body
    }
    return dataList
  } catch (error) {
    console.log(`${queriesBuyerString.error} ${error}`)
  }
}

export const getElements = async (authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate, handleShowInactiveSessionModal
  } = authContext
  const { authTk, elements, ...restSessionData } = sessionData
  if (elements && elements.length > 0) {
    return elements
  }
  let token = await amplify.getToken()
  const isToken = amplify.validateToken(token)
  if (!isToken) {
    amplify.cleanToken()
    await amplify.sign()
    token = await amplify.getToken()
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authTk}`,
      TokenCatalogo: token
    }
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/element`,
      requestOptions
    )
    if (!response.ok) {
      throw new Error(queriesBuyerString.authenticationError)
    }
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    if (data.body) {
      const dataList = data.body.map((item) => ({
        elementId: item.elementId,
        elementName: item.elementName,
        subcategoryId: item.subCategoryId.subCategoryId,
        subcategoryName: item.subCategoryId.subCategoryName,
        subcategoryIcon: item.subCategoryId.iconpath,
        categoryId: item.subCategoryId.categoryId.categoryId,
        categoryName: item.subCategoryId.categoryId.categoryName,
        categoryIcon: item.subCategoryId.categoryId.iconpath
      }))
      const updatedSessionData = {
        ...restSessionData,
        elements: dataList,
        authTk
      }
      setSessionData(updatedSessionData)
      return dataList
    }
  } catch (error) {
    console.log(`${queriesBuyerString.error} ${error}`)
  }
}

export const createCompanyQuery = async (companyData, buyerData, reCaptcha) => {
  const createCompanyDto = {
    companyBussinesName: companyData.bussinessName,
    companyNit: companyData.nit,
    cityId: parseInt(companyData.city),
    companyComertialName: companyData.comertialName,
    companyPhone: buyerData.notificationNumber,
    companyEmail: buyerData.notificationEmail,
    directRemitent: true
  }
  const file = companyData.entityLogo

  const body = {
    createCompanyDto,
    files: {},
    captcha: reCaptcha
  }
  const files = {
    value: 'logos',
    fileNames: [{ fileName: textWithoutEspecials(file.name), contentType: file.type }]
  }
  body.files = files

  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TokenCatalogo: token
      },
      body: JSON.stringify(body)
    })
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }
    const res = await response.json()
    if ('doc' in res) {
      await fetch(res.doc[textWithoutEspecials(file.name)].url, {
        method: 'PUT',
        body: file
      })
    }
    return res
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const createBuyerQuery = async (
  buyerData,
  companyData,
  companyId,
  requestId
) => {
  const createBuyerDto = {
    companyId,
    buyerAddress: buyerData.correspondenceAddress,
    buyerLegalRepresentative: buyerData.legalRepresentativeName,
    buyerContactName: buyerData.contactPersonName,
    buyerActive: true,
    buyerRepresentativeIdType: buyerData.representativeDocType,
    buyerRepresentativeIdNum: buyerData.idNum,
    companyNit: companyData.nit,
    buyerExpeditionCity: buyerData.expeditionCity,
    requestId
  }
  const commerceChamberFile = companyData.commerceChamber
  const rutFile = companyData.rut
  const body = {
    createBuyerDto,
    files: {}
  }
  const files = {
    rut: {
      value: 'company',
      fileNames: [{ fileName: textWithoutEspecials(rutFile.name), contentType: rutFile.type }]
    },
    camera: {
      value: 'camera',
      fileNames: [{ fileName: textWithoutEspecials(commerceChamberFile.name), contentType: commerceChamberFile.type }]
    }
  }
  body.files = files
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/buyer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TokenCatalogo: token
      },
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }
    const res = await response.json()
    await fetch(res.doc.rut[textWithoutEspecials(rutFile.name)].url, {
      method: 'PUT',
      body: rutFile
    })
    await fetch(res.doc.camera[textWithoutEspecials(commerceChamberFile.name)].url, {
      method: 'PUT',
      body: commerceChamberFile
    })
    return res
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const createBuyerCredentialsQuery = async (
  buyerId,
  buyerCredentialEmail,
  buyerCredentialPass
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential`,
      {
        method: 'POST',
        body: JSON.stringify({
          buyerId,
          buyerCredentialEmail,
          buyerCredentialPass,
          role: 7
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }
    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const validateBuyerQuery = async (email, password, code) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential/confirmation`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
          code
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }
    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const getPurchasedDB = async (
  prevBuyerId,
  idToken,
  navigate,
  handleShowInactiveSessionModalParam,
  authContext
) => {
  let sessionData,
    setSessionData,
    navigated,
    handleShowInactiveSessionModal,
    buyerId,
    authTk

  if (authContext) {
    const {
      sessionData: sessionDataLocal,
      setSessionData: setSessionDataLocal,
      navigate: navigateLocal,
      handleShowInactiveSessionModal: handleShowInactiveSessionModalLocal
    } = authContext
    sessionData = sessionDataLocal
    setSessionData = setSessionDataLocal
    navigated = navigateLocal
    handleShowInactiveSessionModal = handleShowInactiveSessionModalLocal
    if (sessionData) {
      buyerId = sessionData.buyerId
      authTk = sessionData.authTk
    }
  } else {
    buyerId = prevBuyerId
    authTk = idToken
    navigated = navigate
    handleShowInactiveSessionModal = handleShowInactiveSessionModalParam
  }
  if (!buyerId) return
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/buyers/${buyerId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }
    const data = await handleSessionFailures(
      response,
      navigated,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const postPQRS = async (
  pqrsId,
  content,
  buyerId,
  contextInfo,
  file = null
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const dataBody = {
      pqrsId,
      content: content + '\n',
      statusId: 1,
      buyerId
    }
    let body = JSON.stringify(dataBody)
    let url = `${process.env.REACT_APP_HOST}/request`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: contextInfo.sessionInfo.loginCognito.idToken,
      TokenCatalogo: token
    }
    if (pqrsId === 8) {
      const bodyTemp = {
        createRequestDto: dataBody,
        files: {}
      }
      const files = {
        value: 'bills',
        fileNames: [{ fileName: textWithoutEspecials(file.name), contentType: file.type }]
      }
      const pqrs = {
        bills: files
      }
      bodyTemp.files = pqrs
      body = JSON.stringify(bodyTemp)
      url = `${process.env.REACT_APP_HOST}/request/PQRS/doc`
    }
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body
    })

    const res = await response.json()
    if ('doc' in res) {
      await fetch(res.doc[textWithoutEspecials(file.name)].url, {
        method: 'PUT',
        body: file
      })
    }
    return res
  } catch (error) {
    console.log(`${queriesBuyerString.error} ${error}`)
  }
}

export const getPQRS = async (authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await (
      await fetch(`${process.env.REACT_APP_HOST}/pqrs`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      })
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    if (data.statusCode === 404) {
      return {
        response: false,
        body: [{ pqrsId: 1, pqrsName: queriesBuyerString.tehereAreNoPqrs }]
      }
    }

    return data
  } catch (error) {
    return {
      response: false,
      body: [{ pqrsId: 1, pqrsName: queriesBuyerString.tehereAreNoPqrs }]
    }
  }
}

export const getPqrIds = async (authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/pqrs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTk}`,
        TokenCatalogo: token
      }
    })
    if (!response.ok) {
      throw new Error(queriesBuyerString.authenticationError)
    }
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.log(`${queriesBuyerString.error} ${error}`)
  }
}

export const validateBuyerRepresentativeIdNumQuery = async (idNum) => {
  if (!idNum) {
    throw new Error(queriesBuyerString.idNum)
  } else if (typeof idNum !== 'string') {
    throw new Error(queriesBuyerString.idNumNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/idNum/${idNum}`,
      {
        headers: {
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }

    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const validateEmailInRegistrationForm = async (
  companyNit,
  companyEmail
) => {
  if (!companyNit || !companyEmail) {
    throw new Error(queriesBuyerString.companyNull)
  } else if (
    typeof companyNit !== 'string' ||
    typeof companyEmail !== 'string'
  ) {
    throw new Error(queriesBuyerString.companyNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/validate`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyNit,
          companyEmail
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }

    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const validatePhoneInRegistrationForm = async (
  companyNit,
  companyPhone
) => {
  if (!companyNit || !companyPhone) {
    throw new Error(queriesBuyerString.companyPhoneNull)
  } else if (
    typeof companyNit !== 'string' ||
    typeof companyPhone !== 'string'
  ) {
    throw new Error(queriesBuyerString.companyNotString2)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/validate`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyNit,
          companyPhone
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }

    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}

export const saveUserData = (email, pass) => {
  const encryptedPass = CryptoJS.AES.encrypt(pass, 'tudata-secret-key').toString()
  localStorage.setItem('buyerEmail', email)
  localStorage.setItem('buyerPass', encryptedPass)
}

export const getUserData = () => {
  const buyerEmail = localStorage.getItem('buyerEmail') ?? ''
  const encryptedPass = localStorage.getItem('buyerPass') ?? ''
  const decryptedPass = CryptoJS.AES.decrypt(encryptedPass, 'tudata-secret-key').toString(CryptoJS.enc.Utf8)
  return { buyerEmail, buyerPass: decryptedPass }
}

export const validateExistingNit = async (
  companyNit,
  companyBussinesName,
  companyComertialName
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/validateExistingNit`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyNit,
          companyBussinesName,
          companyComertialName
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }

    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(`${queriesBuyerString.error} ${error}`)
  }
}
