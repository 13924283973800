import React, { useState, createRef, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Anchor, Form } from 'react-bootstrap'
import logo from '../../assets/icons/logo.svg'
import openEyeIcon from '../../assets/icons/icon_pass2.svg'
import closeEyeIcon from '../../assets/icons/icon_pass1.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import {
  companyRegistration2BuyerString,
  companyRegistrationBuyerString,
  loginBuyerString,
  passwordChangeBuyerString
} from '../../utils/strings'
import {
  eyeShowPassword,
  handleSubmit
} from './CompanyRegistration3Functions'
import { maxSize, validatePassFormat } from '../../utils/validateFunctions'
import './CompanyRegistration3.css'
import ReCAPTCHA from 'react-google-recaptcha'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

export const CompanyRegistration3 = () => {
  const { state: registerData } = useLocation()
  const captcha = createRef()
  const navigate = useNavigate()

  const [eyeIcon1, setEyeIcon1] = useState(openEyeIcon)
  const [eyeIcon2, setEyeIcon2] = useState(openEyeIcon)
  const [showIncorrectFormatPass, setShowIncorrectFormatPass] = useState('showSpanPass')
  const [showRePasswordIncorrectFormat, setShowRePasswordIncorrectFormat] = useState('showSpanPass')
  const [isRequesting, setIsRequesting] = useState(false)

  const [reCaptcha, setReCaptcha] = useState()
  const [password, setPassword] = useState('')
  const [passwordValidation, setPasswordValidation] = useState({
    validFormat: false,
    equalPasswords: false
  })
  const [rePassword, setRePassword] = useState('')

  useEffect(() => {
    const passFormat = validatePassFormat(password)
    setPasswordValidation({
      validFormat: passFormat,
      equalPasswords: password === rePassword
    })
  }, [password, rePassword])

  const captchaChange = () => {
    setReCaptcha(captcha.current.getValue())
  }

  const handleShowWrongMessages = () => {
    const result = validatePassFormat(password)
    result ? setShowIncorrectFormatPass('showSpanPass') : setShowIncorrectFormatPass('incorrectFormat')
  }

  const handleEqualPasswords = () => {
    if (password === rePassword) setShowRePasswordIncorrectFormat('showSpanPass')
    else setShowRePasswordIncorrectFormat('incorrectFormat')
  }

  return (
    <div className="companyRegistration2MainContainer">
      <section className="companyRegistration2SubContainer1">
        <div className="companyRegistration2ImgPragraphContainer">
          <img
            src={logo}
            className="companyRegistration2SubContainer1Logo"
            alt={companyRegistrationBuyerString.tuDataLogo}
          ></img>
          <p className="companyRegistration2Container1Paragraph">
            {companyRegistrationBuyerString.completeYourProfile}
          </p>
        </div>
        <h2 className="companyRegistration2Container1H2">
          {companyRegistrationBuyerString.welcome}
          <br />
          {companyRegistrationBuyerString.portal}
        </h2>
        <Link to={'../RegistroEmpresa2'} state={registerData} className="compayRegistrationReturnParagraph">
          <img
            className="returnArrow"
            src={arrowIcon}
            alt={companyRegistrationBuyerString.backArrow}
          />
          {companyRegistrationBuyerString.back}
        </Link>
      </section>
      <section className="companyRegistration2SubContainer2">
        <Link to="/login" className="companyRegistration2LogInLink1">
          {companyRegistrationBuyerString.areYouRegistered}{' '}
          <span className="companyRegistration2LogInLink2">
            {companyRegistrationBuyerString.logIn}
          </span>
        </Link>
        <div className="companyRegistration2Inputs">
          <Form
            className="companyRegistrationForm"
            onSubmit={(event) =>
              handleSubmit(
                event,
                registerData,
                password,
                navigate,
                isRequesting,
                setIsRequesting,
                reCaptcha
              )
            }
          >
            <div className="companyRegistration3Inputs">
              <div>
                <Form.Label className="companyRegistration2Container2FormPassword">
                  <Form.Control
                    className="companyRegistration2Container2Form"
                    autoComplete="off"
                    type="password"
                    onInput={(e) => maxSize(e, 'password')}
                    value={password}
                    placeholder={companyRegistration2BuyerString.password}
                    name="buyerCredentialPass"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleShowWrongMessages}
                  />
                  <Anchor
                    className="showPassButtonInicioSesion"
                    onClick={() => {
                      eyeShowPassword(
                        'buyerCredentialPass',
                        setEyeIcon1,
                        openEyeIcon,
                        closeEyeIcon
                      )
                    }}
                  >
                    <img
                      className="companyRegistration2EyeIcon"
                      src={eyeIcon1}
                      alt="showPass"
                    />
                  </Anchor>
                </Form.Label>
                <span className={showIncorrectFormatPass}>
                  {loginBuyerString.spanFormatPass}
                </span>
              </div>
              <div>
                <Form.Label className="companyRegistration2Container2FormPassword">
                  <Form.Control
                    className="companyRegistration2Container2Form"
                    autoComplete="off"
                    type="password"
                    onInput={(e) => maxSize(e, 'password')}
                    placeholder={companyRegistration2BuyerString.repeatPassword}
                    name="buyerCredentialRePass"
                    onChange={(e) => setRePassword(e.target.value)}
                    onBlur={handleEqualPasswords}
                  />
                  <Anchor
                    className="showPassButtonInicioSesion"
                    onClick={() => {
                      eyeShowPassword(
                        'buyerCredentialRePass',
                        setEyeIcon2,
                        openEyeIcon,
                        closeEyeIcon
                      )
                    }}
                  >
                    <img
                      className="companyRegistration2EyeIcon"
                      src={eyeIcon2}
                      alt="showPass"
                    />
                  </Anchor>
                </Form.Label>
                <span className={showRePasswordIncorrectFormat}>
                  {passwordChangeBuyerString.notSame}
                </span>
              </div>
            </div>
            <ReCAPTCHA
              ref={captcha}
              sitekey={RECAPTCHA_KEY}
              onChange={captchaChange}
              size="normal"
              badge="inline"
            />
            <button
              type="submit"
              className="companyRegistration2ContinueButton"
              disabled={isRequesting || !passwordValidation.validFormat || !passwordValidation.equalPasswords || !reCaptcha}
            >
              {companyRegistrationBuyerString.sendRegister}
            </button>
          </Form>
        </div>
      </section>
    </div>
  )
}
