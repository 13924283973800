import { employeeString } from './strings'
import { hasRepeatedCharactersAtMiddle, validateEmailFormat, validateNames, validateNoWhiteSpacesAtBeginning } from './validateFunctions'

export const onNameChange = (e, inputValues, setInputValues) => {
  const name = e.target.value.replace(/\s+/g, ' ')
  if (validateNames(name)) {
    setInputValues({ ...inputValues, comertialName: name })
  }
}

export const onFormInputChange = (e, inputValues, setInputValues, validateFunction, incorrectInputFormat, setIncorrectInputFormat) => {
  const element = e.target.value
  if (validateNoWhiteSpacesAtBeginning(element)) {
    if (hasRepeatedCharactersAtMiddle(element)) {
      return
    }
    setInputValues({ ...inputValues, [e.target.name]: element.trimStart() })
  }
  setIncorrectInputFormat({ ...incorrectInputFormat, comertialNameformat: 'editCompanyFormItemInput' })
  if (validateFunction(element)) {
    setInputValues({ ...inputValues, [e.target.name]: element.trimStart() })
  }
}

export const onIsValidInputBlur = (e, inputValues2, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat) => {
  const formItem = e.target.name
  const value = inputValues2[formItem]
  if (!value) {
    setSucessFormat((prevState) => ({ ...prevState, [formItem]: false }))
    setIncorrectInputFormat({ ...incorrectInputFormat, [`${e.target.name}Format`]: 'employeeIncorrectFormat' })
    return false
  } else {
    setSucessFormat((prevState) => ({ ...prevState, [formItem]: true }))
    setIncorrectInputFormat({ ...incorrectInputFormat, [`${e.target.name}Format`]: 'employeeRightFormat' })
    return true
  }
}

export const onIsValidNameBlur = (formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const { name } = formValues
  if (!name) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectFormat' })
    return false
  } else if (name.length < 3) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectUserName', nameMessage: 'El nombre es corto.' })
    return false
  } else if (name.startsWith(' ')) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectUserName', nameMessage: 'El nombre no puede comenzar con espacio.' })
    return false
  } else {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeRightFormat' })
    return true
  }
}

export const onNitChange = (e, inputValues, setInputValues, setSucessFormat) => {
  const value = e.target.value
  setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: value.length === 9 }))
  const regEx = /^(|([1-9]\d*))$/
  if (!regEx.test(value)) {
    return null
  } else {
    setInputValues({ ...inputValues, [e.target.name]: value })
  }
}

export const validateNitLength = (text) => {
  const regEx = /^.{9}$/
  return regEx.test(text)
}

export const onPhoneChange = (
  e,
  inputValues2,
  setInputValues2
) => {
  const phone = validateCellphone(e)
  if (phone) {
    setInputValues2({ ...inputValues2, notificationNumber: phone })
  } else setInputValues2({ ...inputValues2, notificationNumber: '' })
}

export const validateCellphone = (e) => {
  let phoneStr = e.target.value
  const regEx = /^(|([1-9]\d*))$/
  if (regEx.test(phoneStr)) {
    if (phoneStr.startsWith('3') || phoneStr.startsWith('6') || phoneStr.length === 0) {
      phoneStr = phoneStr.slice(0, 10)
      return phoneStr
    }
  }
}

export const onCreateEmailChange = (e, setFormValues) => {
  const element = e.target.value.replace(/\s+/g, '')
  setFormValues((inputValues2) => ({ ...inputValues2, [e.target.name]: element.trimStart() }))
}

export const onDocumentRepNumberChange = (e, inputValues2, setInputValues2) => {
  const documentNumber = e.target.value
  const formattedIdNum = documentNumber.replace(/[^0-9]/g, '')

  if (validateBeginWithZero(formattedIdNum) || !validateOnlyIntegersIncludingZero(formattedIdNum)) return

  setInputValues2({ ...inputValues2, idNum: formattedIdNum.substring(0, 10) })
}

export const validateBeginWithZero = (text) => {
  const regEx = /^0/
  return regEx.test(text)
}

export const validateOnlyIntegersIncludingZero = (text) => {
  const regEx = /^[0-9]*$/
  return regEx.test(text)
}

export const onIsValidRepDocumentNumberBlur = async (
  e,
  idNum,
  incorrectInputFormat,
  setIncorrectInputFormat,
  setSucessFormat
) => {
  let documentNumberFormat = 'employeeRightFormat'
  let documentNumberMessage = ''
  let valueToReturn = true

  if (!idNum) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.mandatoryField
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  } else if (idNum < 10000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.biggerNumber
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  } else if (idNum > 2000000000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.minorNumber
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  }

  setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: true }))

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    documentNumberFormat,
    documentNumberMessage
  })

  return valueToReturn
}

export const onIsValidExpCityBlur = (
  e,
  expeditionCity,
  setInputValues2,
  incorrectInputFormat,
  setIncorrectInputFormat,
  citiesFetched,
  setSucessFormat
) => {
  if (!expeditionCity) {
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.mandatoryField
    })
    return false
  }
  setIncorrectInputFormat({ ...incorrectInputFormat, documentExpeditionCityFormat: 'employeeRightFormat' })
  setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  let cityFound = citiesFetched.find(
    (e) =>
      e.cityName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      expeditionCity
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )
  if (!cityFound) {
    cityFound = citiesFetched.find(
      (e) =>
        e.cityName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim() ===
        (
          (expeditionCity
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .includes(
              'Bogotá'
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim()
            ))
            ? 'Bogotá D.C.'
            : ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim()
    )
    if (cityFound) {
      setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: true }))
      setInputValues2((inputValues2) => ({
        ...inputValues2,
        expeditionCity: cityFound.cityName
      }))
      return true
    }
  }

  if (cityFound) {
    setInputValues2((inputValues2) => ({
      ...inputValues2,
      expeditionCity: cityFound.cityName
    }))
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: true }))
    return true
  } else if (!cityFound && expeditionCity !== '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.incorrectCity
    })
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
    setInputValues2((inputValues2) => ({ ...inputValues2, expeditionCity: '' }))
    return false
  }
}

export const onAddressChange = (e, inputValues2, setInputValues2) => {
  if (validateAddress(e.target.value)) {
    setInputValues2({ ...inputValues2, [e.target.name]: e.target.value })
  }
}

export const validateAddress = (text) => {
  const regEx = /^[A-Za-z0-9\s#áéíóúÁÉÍÓÚüÜ.,()-]+$/u
  return regEx.test(text)
}

export const onIsValidPhoneBlur = async (
  e,
  phone,
  incorrectInputFormat,
  setIncorrectInputFormat,
  setSucessFormat
) => {
  let notificationNumberFormat = 'employeeRightFormat'
  let notificationNumberMessage = ''
  let valueToReturn = true
  setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: true }))

  if (!phone) {
    notificationNumberFormat = 'employeeIncorrectFormat'
    notificationNumberMessage = employeeString.mandatoryField
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  } else if (phone.length !== 10 || !phone.startsWith('3')) {
    notificationNumberFormat = 'employeeIncorrectFormat'
    notificationNumberMessage = employeeString.incorrectPhone
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    notificationNumberFormat,
    notificationNumberMessage
  })
  return valueToReturn
}

export const onIsValidEmailBlur = async (
  e,
  notificationEmail,
  incorrectInputFormat,
  setIncorrectInputFormat,
  setSucessFormat
) => {
  let emailFormat = 'employeeRightFormat'
  let emailMessage = ''
  let valueToReturn = true
  setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: true }))

  if (!notificationEmail) {
    emailFormat = 'employeeIncorrectFormat'
    emailMessage = employeeString.mandatoryField
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  } else if (!validateEmailFormat(notificationEmail)) {
    emailFormat = 'employeeIncorrectFormat'
    emailMessage = employeeString.incorrectEmailFormat
    valueToReturn = false
    setSucessFormat((prevState) => ({ ...prevState, [e.target.name]: false }))
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, emailFormat, emailMessage })
  return valueToReturn
}

export const validateInputOnBlur = async (
  e,
  inputValues2,
  validateInputFormatFn,
  setIncorrectInputFormat,
  incorrectInputFormat,
  setSucessFormat
) => {
  const formItem = e.target.name
  const value = inputValues2[formItem]
  if (!value) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nitFormat: 'employeeIncorrectFormat' })
    return false
  } else {
    if (!validateInputFormatFn(e.target.value)) {
      setSucessFormat((prevState) => ({ ...prevState, [formItem]: false }))
    } else {
      setSucessFormat((prevState) => ({ ...prevState, [formItem]: true }))
    }
    setIncorrectInputFormat({ ...incorrectInputFormat, nitFormat: 'employeeRightFormat' })
    return true
  }
}
