import { validateExistingNit } from '../../utils/queries'
import { getCities, getStates } from '../../utils/userqueries'

export const handleContinue = async (e, inputValues, citiesFetched, navigate) => {
  e.preventDefault()
  const {
    nit: companyNit,
    comertialName: companyComertialName,
    bussinessName: companyBussinesName
  } = inputValues

  const validateExistingNitResponse = await validateExistingNit(companyNit, companyBussinesName, companyComertialName)
  if (validateExistingNitResponse.response) {
    navigate('/RegistroEmpresa2', { state: { companyData: inputValues, citiesFetched } })
  } else {
    if (validateExistingNitResponse?.data?.code.includes('COMPANY_BUSSINES_NAME_DOES_NOT_MATCH')) {
      alert('El nombre empresarial digitado no corresponde al NIT digitado.')
    } else if (validateExistingNitResponse?.data?.code.includes('COMPANY_COMERTIAL_NAME_ALREADY_EXISTS')) {
      alert('El nombre comercial ya se encuentra registrado.')
    }
  }
}

export const getPlaces = async () => {
  const { states } = await getStates()
  const { cities } = await getCities()

  return { states, cities }
}

export const filterCities = (cities, locationSelected) => {
  const location = locationSelected || 1
  const citiesFiltered = cities.filter(
    (city) => city.stateId.stateId === Number(location)
  )
  return citiesFiltered
}
