import React, { useEffect, useState } from 'react'
import logo from '../../assets/icons/logo.svg'

import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { companyRegistration2BuyerString, companyRegistrationBuyerString } from '../../utils/strings'

import './CompanyRegistration2.css'
import { maxSize, validateCompanyName } from '../../utils/validateFunctions'
import { onAddressChange, onCreateEmailChange, onDocumentRepNumberChange, onFormInputChange, onIsValidEmailBlur, onIsValidExpCityBlur, onIsValidInputBlur, onIsValidPhoneBlur, onIsValidRepDocumentNumberBlur, onPhoneChange } from '../../utils/createAndEditUserMethods'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import { createRegistrationDataList, handleContinue2 } from './CompanyRegistration2Functions'

export const CompanyRegistration2 = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { citiesFetched } = state

  const { incorrectInputFormat, setIncorrectInputFormat } = useIncorrectInputFormat()

  const [inputValues2, setInputValues2] = useState({
    legalRepresentativeName: null,
    representativeDocType: null,
    idNum: null,
    expeditionCity: null,
    contactPersonName: null,
    correspondenceAddress: null,
    notificationNumber: '',
    notificationEmail: null
  })

  const [sucessFormat, setSucessFormat] = useState({
    legalRepresentativeName: false,
    idNum: inputValues2.idNum?.length === 10,
    expeditionCity: false,
    contactPersonName: false,
    correspondenceAddress: false,
    notificationNumber: inputValues2.notificationNumber?.length === 10,
    notificationEmail: false
  })

  const [identList, setIdentList] = useState([])

  useEffect(() => {
    createRegistrationDataList({ setIdentList })
  }, [])

  return (
    <div className='companyRegistration2MainContainer'>
      <section className='companyRegistration2SubContainer1'>
        <div className='companyRegistration2ImgPragraphContainer'>
          <img src={logo} className='companyRegistration2SubContainer1Logo' alt={companyRegistrationBuyerString.tuDataLogo}></img>
          <p className='companyRegistration2Container1Paragraph'>{companyRegistrationBuyerString.completeYourProfile}</p>
        </div>
        <h2 className='companyRegistration2Container1H2'>{companyRegistrationBuyerString.welcome}<br />{companyRegistrationBuyerString.portal}</h2>
        <Link to='../RegistroEmpresa' state={state} className='compayRegistrationReturnParagraph'><img className='returnArrow' src={arrowIcon} alt={companyRegistrationBuyerString.backArrow} />{companyRegistrationBuyerString.back}</Link>
      </section>
      <section className='companyRegistration2SubContainer2'>
        <Link to='/login' className='companyRegistration2LogInLink1'>{companyRegistrationBuyerString.areYouRegistered} <span className='companyRegistration2LogInLink2'>{companyRegistrationBuyerString.logIn}</span></Link>
        <div className='companyRegistration2Inputs'>
          <p className='obligatoryField'>{companyRegistrationBuyerString.requiredInput}</p>
          <Form className='companyRegistrationForm' onSubmit={(e) => handleContinue2(e, inputValues2, state, navigate)} >
            <div className='companyRegistration2InputsContainer'>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  maxLength={48}
                  value={inputValues2.legalRepresentativeName}
                  placeholder={companyRegistration2BuyerString.legalRepresentative}
                  name='legalRepresentativeName'
                  onInput={(e) => maxSize(e, 'compName')}
                  onChange={(e) => onFormInputChange(e, inputValues2, setInputValues2, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
                  onBlur={(e) => onIsValidInputBlur(
                    e,
                    inputValues2,
                    incorrectInputFormat,
                    setIncorrectInputFormat,
                    setSucessFormat
                  )}
                />
                <span className={incorrectInputFormat.legalRepresentativeNameFormat}>
                  {incorrectInputFormat.legalRepresentativeNameMessage}
                </span>
              </div>
              <div>
                <Form.Select
                  size="sm"
                  className="companyRegistrationContainer2Form"
                  name="buyerRepresentativeIdType"
                  onChange={(e) => setInputValues2({ ...inputValues2, representativeDocType: e.target.value })}
                >
                  <option key="buyerRepresentativeIdType" hidden value="">
                    {companyRegistration2BuyerString.idRepresentatitve}
                  </option>
                  {identList?.map((item) => (
                    <option
                      key={item.identDocId + item.identDocName}
                      value={item.identDocId}
                    >
                      {item.identDocName}
                    </option>
                  ))}
                </Form.Select>
                <span className={incorrectInputFormat.documentTypeIdFormat}>
                  {incorrectInputFormat.documentTypeIdMessage}
                </span>
              </div>
              <div className='idInputsContainer'>
                <Form.Control
                  className='idNumInput'
                  autoComplete='off'
                  value={inputValues2.idNum}
                  placeholder={companyRegistration2BuyerString.idNumber}
                  name='idNum'
                  onChange={(e) => onDocumentRepNumberChange(e, inputValues2, setInputValues2)}
                  onBlur={(e) => onIsValidRepDocumentNumberBlur(e, inputValues2.idNum, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                />
                <Form.Control
                  className='expeditionCityInput'
                  autoComplete='off'
                  type='text'
                  value={inputValues2.expeditionCity}
                  placeholder={companyRegistration2BuyerString.expeditionCity}
                  name='expeditionCity'
                  onChange={(e) => setInputValues2({ ...inputValues2, expeditionCity: e.target.value })}
                  onBlur={(e) => onIsValidExpCityBlur(e, inputValues2.expeditionCity, setInputValues2, incorrectInputFormat, setIncorrectInputFormat, citiesFetched, setSucessFormat)}
                />
              </div>
              <div className='idNumAndExpeditionCityErrorMessages'>
                <span className={incorrectInputFormat.documentNumberFormat}>
                  {incorrectInputFormat.documentNumberMessage}
                </span>
                <span className={incorrectInputFormat.documentExpeditionCityFormat}>
                  {incorrectInputFormat.documentExpeditionCityMessage}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  value={inputValues2.contactPersonName}
                  placeholder={companyRegistration2BuyerString.contactPerson}
                  maxLength='48'
                  name='contactPersonName'
                  onChange={(e) => setInputValues2({ ...inputValues2, contactPersonName: e.target.value })}
                  onBlur={(e) => onIsValidInputBlur(e, inputValues2, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                />
                <span className={incorrectInputFormat.contactPersonNameFormat}>
                  {incorrectInputFormat.contactPersonNameMessage}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  value={inputValues2.correspondenceAddress}
                  maxLength={48}
                  placeholder={companyRegistration2BuyerString.dirCorrespondence}
                  name='correspondenceAddress'
                  onChange={(e) => onAddressChange(e, inputValues2, setInputValues2)}
                  onBlur={(e) => onIsValidInputBlur(e, inputValues2, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                />
                <span className={incorrectInputFormat.correspondenceAddressFormat}>
                  {incorrectInputFormat.correspondenceAddressMessage}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='text'
                  value={inputValues2.notificationNumber}
                  placeholder={companyRegistration2BuyerString.telNotification}
                  name='notificationNumber'
                  onChange={(e) => onPhoneChange(e, inputValues2, setInputValues2)}
                  onBlur={(e) => onIsValidPhoneBlur(e, inputValues2.notificationNumber, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                />
                <span className={incorrectInputFormat.notificationNumberFormat}>
                  {incorrectInputFormat.notificationNumberMessage}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='email'
                  value={inputValues2.notificationEmail}
                  maxLength={48}
                  placeholder={companyRegistration2BuyerString.emailNotification}
                  name='notificationEmail'
                  onChange={(e) => onCreateEmailChange(e, setInputValues2)}
                  onBlur={(e) => onIsValidEmailBlur(e, inputValues2.notificationEmail, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                />
                <span className={incorrectInputFormat.emailFormat}>
                  {incorrectInputFormat.emailMessage}
                </span>
              </div>
            </div>
            <button
              type='submit'
              className='companyRegistration2ContinueButton'
              disabled={Object.values(inputValues2).some(value => value === null) || Object.values(sucessFormat).some(value => value === false)}
            >
              {companyRegistrationBuyerString.continue}
            </button>
          </Form>
        </div>
      </section>
    </div>
  )
}
