import {
  activateRequestButton,
  handleRequestButton,
  passwordEncrypt
} from '../../utils/functions'
import {
  createBuyerCredentialsQuery,
  createBuyerQuery,
  createCompanyQuery
} from '../../utils/queries'
import { companyRegistrationBuyerString } from '../../utils/strings'
import { validatePassFormat } from '../../utils/validateFunctions'

export const hadleCompanyFormChange = (
  e,
  setShowRePasswordIncorrectFormat,
  setAreValidCredentials,
  areValidCredentials,
  buyerCredentialPass,
  setShowPasswordIncorrectFormat,
  setshowIncorrectFormatPass
) => {
  if (e.target.name === 'buyerCredentialRePass') {
    if (validatePassFormat(e.target.value)) {
      setShowRePasswordIncorrectFormat('showSpanPass')
      setAreValidCredentials({
        ...areValidCredentials,
        credentialRePass: true
      })
    } else {
      setShowRePasswordIncorrectFormat('incorrectFormat')
      setAreValidCredentials({
        ...areValidCredentials,
        credentialRePass: false
      })
    }
  } else if (e.target.name === 'buyerCredentialPass') {
    if (validatePassFormat(e.target.value)) {
      setshowIncorrectFormatPass('showSpanPass')
      setAreValidCredentials({ ...areValidCredentials, credentialPass: true })
    } else {
      setshowIncorrectFormatPass('incorrectFormat')
      setAreValidCredentials({ ...areValidCredentials, credentialPass: false })
    }
  }
}

export const CreateCompanyAndBuyer = async (
  registerData,
  passwordRaw,
  reCaptcha
) => {
  const { companyData, buyerData } = registerData
  const { notificationEmail } = buyerData
  try {
    const createCompanyResponse = await createCompanyQuery(
      companyData,
      buyerData,
      reCaptcha
    )

    let companyId = null
    let requestId = null

    if (createCompanyResponse.response && createCompanyResponse?.data?.body) {
      companyId = createCompanyResponse.data.body.companyId
      requestId = createCompanyResponse.data.body.requestId
    }

    const createBuyerResponse = await createBuyerQuery(
      buyerData,
      companyData,
      companyId,
      requestId
    )

    if (createBuyerResponse.response) {
      const password = passwordEncrypt(passwordRaw)
      const createBuyerCredentialsResponse = await createBuyerCredentialsQuery(
        createBuyerResponse.data.body.buyerId,
        notificationEmail,
        password
      )

      return createBuyerCredentialsResponse
    }
    return null
  } catch (error) {
    console.error(companyRegistrationBuyerString.mistake, error)
  }
}

export const eyeShowPassword = (
  inputName,
  setEyeIcon,
  openEyeIcon,
  closeEyeIcon
) => {
  const element = document.getElementsByName(inputName)[0]
  if (element.type === 'password') {
    element.type = 'text'
    setEyeIcon(closeEyeIcon)
  } else {
    element.type = 'password'
    setEyeIcon(openEyeIcon)
  }
  element.focus()
}

export const handleSubmit = async (
  event,
  registerData,
  password,
  navigate,
  isRequesting,
  setIsRequesting,
  reCaptcha
) => {
  event.preventDefault()
  if (isRequesting) return null

  setIsRequesting(true)
  const response = await CreateCompanyAndBuyer(
    registerData,
    password,
    reCaptcha
  )

  if (response?.response) {
    setIsRequesting(false)
    navigate('../RegistroExitoso')
  } else {
    setIsRequesting(false)
  }
}

export const formVerification = async (
  contextInfo,
  companyComertialName,
  buyerAddress,
  buyerLegalRepresentative,
  buyerContactName,
  companyEmail,
  buyerRepresentativeIdNum,
  buyerCredentialPass,
  setShowPasswordIncorrectFormat,
  setShowRePasswordIncorrectFormat,
  navigate,
  setIsRequesting,
  lastTimeOutId,
  reCaptcha
) => {
  if (!buyerCredentialPass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowPasswordIncorrectFormat('incorrectFormat')
  } else {
    setShowPasswordIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.buyerCredentialRePass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowRePasswordIncorrectFormat('incorrectFormat')
  } else if (buyerCredentialPass !== contextInfo.buyerCredentialRePass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowRePasswordIncorrectFormat('incorrectFormat')
  } else {
    setShowRePasswordIncorrectFormat('showSpanPass')
  }

  if (
    buyerLegalRepresentative &&
    contextInfo.buyerRepresentativeIdType &&
    buyerRepresentativeIdNum &&
    buyerContactName &&
    buyerAddress &&
    contextInfo.companyPhone &&
    contextInfo.companyPhone.toString().length === 10 &&
    companyEmail &&
    buyerCredentialPass &&
    validatePassFormat(buyerCredentialPass) &&
    contextInfo.buyerCredentialRePass &&
    buyerCredentialPass === contextInfo.buyerCredentialRePass
  ) {
    const timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
    const response = await CreateCompanyAndBuyer(
      contextInfo,
      companyComertialName,
      buyerAddress,
      buyerLegalRepresentative,
      buyerContactName,
      companyEmail,
      buyerRepresentativeIdNum,
      buyerCredentialPass,
      setIsRequesting,
      timeOutId,
      reCaptcha
    )
    if (!response.response) {
      console.error(response)
    } else {
      navigate('../RegistroExitoso')
    }
  }
}
