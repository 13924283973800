import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { filterCities, getPlaces, handleContinue } from './CompanyRegistrationFunctions'
import { companyRegistrationBuyerString } from '../../utils/strings'
import { maxSize, validateCompanyName } from '../../utils/validateFunctions'
import { onFormInputChange, onIsValidInputBlur, onNitChange, validateInputOnBlur, validateNitLength } from '../../utils/createAndEditUserMethods'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import logo from '../../assets/icons/logo.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import './CompanyRegistration.css'

export const CompanyRegistration = () => {
  const navigate = useNavigate()
  const { incorrectInputFormat, setIncorrectInputFormat } = useIncorrectInputFormat()

  const [inputValues, setInputValues] = useState({
    comertialName: null,
    bussinessName: null,
    entityLogo: null,
    nit: '',
    department: null,
    city: null,
    rut: null,
    commerceChamber: null
  })

  const [sucessFormat, setSucessFormat] = useState({
    comertialName: false,
    bussinessName: false,
    nit: false
  })

  const [places, setPlaces] = useState({
    states: [],
    cities: [],
    citiesFiltered: []
  })

  useEffect(() => {
    const getLocationData = async () => {
      const locData = await getPlaces()
      setPlaces({ states: locData.states, cities: locData.cities })
    }

    getLocationData()
  }, [])

  useEffect(() => {
    if (places.cities.length) {
      const citiesFiltered = filterCities(places.cities, inputValues.department)
      setPlaces({ ...places, citiesFiltered })
    }
  }, [inputValues.department])

  return (
    <div className="companyRegistrationMainContainer">
      <section className="companyRegistrationSubContainer1">
        <div className="companyRegistrationImgPragraphContainer">
          <img
            src={logo}
            className="companyRegistrationSubContainer1Logo"
            alt={companyRegistrationBuyerString.tuDataLogo}
          ></img>
          <p className="companyRegistrationContainer1Paragraph">
            {companyRegistrationBuyerString.completeYourProfile}
          </p>
        </div>
        <h2 className="companyRegistrationContainer1H2">
          {companyRegistrationBuyerString.welcome}
          <br />
          {companyRegistrationBuyerString.portal}
        </h2>
        <Link to="/" className="compayRegistrationReturnParagraph">
          <img
            className="returnArrow"
            src={arrowIcon}
            alt={companyRegistrationBuyerString.backArrow}
          />
          {companyRegistrationBuyerString.back}
        </Link>
      </section>
      <section className="companyRegistrationSubContainer2">
        <Link to="/login" className="companyRegistrationLogInLink1">
          {companyRegistrationBuyerString.areYouRegistered}{' '}
          <span className="companyRegistrationLogInLink2">
            {companyRegistrationBuyerString.logIn}
          </span>
        </Link>

        <div>
          <Form className="companyRegistrationInputs" onSubmit={(e) => handleContinue(e, inputValues, places.cities, navigate)}>
            <p className="obligatoryField">
              {companyRegistrationBuyerString.requiredInput}
            </p>
            <div className="companyRegistrationInputsContainer">
              <Form.Control
                className="companyRegistrationContainer2Form"
                autoComplete="off"
                id="comertialName"
                name="comertialName"
                type="text"
                maxLength="30"
                minLength="3"
                placeholder={companyRegistrationBuyerString.nameCommercialAlly}
                value={inputValues.comertialName}
                onInput={(e) => maxSize(e, 'compName')}
                onChange={(e) => onFormInputChange(e, inputValues, setInputValues, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
                onBlur={(e) => onIsValidInputBlur(e, inputValues, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
                required
              />
              <span className={incorrectInputFormat.comertialNameFormat}>
                {incorrectInputFormat.comertialNameMessage}
              </span>
            </div>
            <div className="companyRegistrationInputsContainer">
              <Form.Control
                className="companyRegistrationContainer2Form"
                autoComplete="off"
                id="bussinessName"
                name="bussinessName"
                type="text"
                maxLength="30"
                placeholder={companyRegistrationBuyerString.companyName}
                value={inputValues.bussinessName}
                onInput={(e) => maxSize(e, 'compName')}
                onChange={(e) => onFormInputChange(e, inputValues, setInputValues, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
                onBlur={(e) => onIsValidInputBlur(e, inputValues, incorrectInputFormat, setIncorrectInputFormat, setSucessFormat)}
              />
              <span className={incorrectInputFormat.bussinessNameFormat}>
                {incorrectInputFormat.bussinessNameMessage}
              </span>
            </div>
            <div className="companyRegistrationInputsContainer">
              <div className="companyRegistrationInputButtonContainer">
                <label
                  htmlFor="fileInputImg"
                  className="companyRegistrationFileCustomButton"
                >
                  *{companyRegistrationBuyerString.companyLogo}
                </label>
                <input
                  type="file"
                  id="fileInputImg"
                  className="hidden"
                  accept=".jpg,.jpeg,.png"
                  name="logo"
                  onChange={(e) => setInputValues({ ...inputValues, entityLogo: e.target.files[0] })}
                />
                <p className={`${inputValues.entityLogo ? 'fileName' : 'fileNameEmpty'}`}>
                  {inputValues.entityLogo ? inputValues.entityLogo.name : 'Ningún archivo seleccionado'}
                </p>
              </div>
            </div>
            <div className="companyRegistrationInputsContainer">
              <Form.Control
                className={'companyRegistrationContainer2Form'}
                autoComplete="off"
                id="nit"
                name="nit"
                type="text"
                value={inputValues.nit}
                onInput={(e) => maxSize(e, 'nit')}
                onChange={(e) => onNitChange(e, inputValues, setInputValues, setSucessFormat)}
                onBlur={(e) => validateInputOnBlur(e, inputValues, validateNitLength, setIncorrectInputFormat, incorrectInputFormat, setSucessFormat)}
                maxLength={9}
                placeholder={companyRegistrationBuyerString.nit}
              />
              <span className={incorrectInputFormat.nitFormat}>
                {incorrectInputFormat.nitMessage}
              </span>
            </div>
            <div className="companyRegistrationInputsContainer">
              <Form.Select
                size="sm"
                className="companyRegistrationContainer2Form"
                name="departmentId"
                onChange={(e) => setInputValues({ ...inputValues, department: e.target.value })}
              >
                <option key="0department" hidden value="">
                  {companyRegistrationBuyerString.department}
                </option>
                {places?.states?.map((item) => (
                  <option
                    key={item.stateId + item.stateName}
                    value={item.stateId}
                  >
                    {item.stateName}
                  </option>
                ))}
              </Form.Select>
            </div>
          <div className="companyRegistrationInputsContainer">
            <Form.Select
              size="sm"
              className="companyRegistrationContainer2Form"
              name="departmentId"
              onChange={(e) => setInputValues({ ...inputValues, city: e.target.value })}
            >
              <option key="0city" hidden value="Default">
                {companyRegistrationBuyerString.city}
              </option>
              {places?.citiesFiltered?.map((item) => (
                <option
                  key={item.cityId + item.cityName}
                  value={item.cityId}
                >
                  {item.cityName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="companyRegistrationInputsContainer">
            <div className="companyRegistrationInputButtonContainer">
              <label
                htmlFor="fileInputDoc"
                className="companyRegistrationFileCustomButton"
              >
                *{companyRegistrationBuyerString.attachDocument}
              </label>
              <input
                type="file"
                id="fileInputDoc"
                className="hidden"
                accept=".jpg,.jpeg,.png,.pdf"
                name="RUToCCFile"
                onChange={(e) => setInputValues({ ...inputValues, rut: e.target.files[0] })}
              />
              <p className={`${inputValues.rut ? 'fileName' : 'fileNameEmpty'}`}>
                {inputValues.rut ? inputValues.rut.name : 'Ningún archivo seleccionado'}
              </p>
            </div>
          </div>
          <div className="companyRegistrationInputsContainer">
            <div className="companyRegistrationInputButtonContainer">
              <label
                htmlFor="fileInputCamCommerce"
                className="companyRegistrationFileCustomButton"
              >
                *{'Cámara de comercio'}
              </label>
              <input
                type="file"
                id="fileInputCamCommerce"
                className="hidden"
                accept=".jpg,.jpeg,.png,.pdf"
                name="RUToCCFile"
                onChange={(e) => setInputValues({ ...inputValues, commerceChamber: e.target.files[0] })}
              />
              <p className={`${inputValues.commerceChamber ? 'fileName' : 'fileNameEmpty'}`}>
                {inputValues.commerceChamber ? inputValues.commerceChamber.name : 'Ningún archivo seleccionado'}
              </p>
            </div>
          </div>
          <button
            className="companyRegistrationContinueButton"
            type='submit'
            disabled={Object.values(inputValues).some(value => value === null) || Object.values(sucessFormat).some(value => value === false)}
          >
            {companyRegistrationBuyerString.continue}
          </button>
        </Form>
        </div>
      </section>
    </div>
  )
}
