import { validateFunctionsBuyerString } from './strings'

export const validateEmailFormat = (email) => {
  // const regex = /^[^\s@]+@[^\s@_]+\.[^\s@]+$/
  const regex = /^[0-9a-zA-Z_.-]{6,30}@[0-9a-zA-Z-]{3,}(\.[0-9a-zA-Z]{2,})+$/
  return regex.test(email)
}

export const validatePassFormat = (text) => {
  const allowedSpecialChars = '!#$%&*,.:?@[]^_{|}~'
  const allowedChars = `a-zA-Z\\d${allowedSpecialChars.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&'
  )}`
  const regExp = new RegExp(
    `^(?=.*\\d)(?=.*[${allowedSpecialChars.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )}])(?=.*[a-z])(?=.*[A-Z]).{8,}$`
  )
  const allowedCharsRegExp = new RegExp(`^[${allowedChars}]+$`)
  return regExp.test(text) && allowedCharsRegExp.test(text)
}

export const checkEqualPassword = (str, inputValue) => {
  return inputValue === str
}

export const convertText = (text) => {
  let textConverted = text.toLowerCase()

  textConverted = textConverted.replace(/[áÁ]/g, 'a')
  textConverted = textConverted.replace(/[éÉ]/g, 'e')
  textConverted = textConverted.replace(/[íÍ]/g, 'i')
  textConverted = textConverted.replace(/[óÓ]/g, 'o')
  textConverted = textConverted.replace(/[úÚüÜ]/g, 'u')

  return textConverted
}

export const maxSize = (e, field = 'other') => {
  let length = null
  field === 'password'
    ? (length = 16)
    : field === 'email' || field === 'flName'
      ? (length = 48)
      : field === 'compName'
        ? (length = 70)
        : field === 'long'
          ? (length = 480)
          : field === 'nit'
            ? (length = 9)
            : field === 'cc'
              ? (length = 10)
              : (length = 10)
  if (e.target.value.length > length) {
    e.target.value = e.target.value.slice(0, length)
  }
}

export const isValidInput = (value) => {
  return /^[A-Za-z]+$/.test(value)
}

export const validateCellphone = (e) => {
  let phoneStr = e.target.value
  const regEx = /^[1-9]\d*$/

  if (regEx.test(phoneStr)) {
    if (phoneStr.startsWith('3')) {
      phoneStr = phoneStr.slice(0, 10)
      return phoneStr
    }
  }
}

export const validateCompanyName = (text) => {
  const regEx = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ,0-9\s]+$/u
  return regEx.test(text)
}

export const validateDataBaseExpiration = (dbpurchased) => {
  return dbpurchased.map((item) => {
    if (item.statusId.statusId === 1) return 'Pendiente'
    else {
      const dueDate = new Date(item.expeditionDate)
      const actualDate = new Date()

      const timeDueDateUTC = dueDate.toISOString().split('T')[0]
      const timeActualDateUTC = actualDate.toISOString().split('T')[0]

      if (timeDueDateUTC < timeActualDateUTC) return 'Vencida'
      else if (timeDueDateUTC === timeActualDateUTC) return 'Activa'
      else return 'Activa'
    }
  })
}

export const validateRepeatedCharacters = (text) => {
  // Esta función NO permite ingresar el mismo caracter más de 2 veces seguidas y NO permite ingresar el caracter punto (.) más de 3 veces seguidas
  const lastTwoChars = []
  if (text.length > 1) {
    lastTwoChars[0] = text[text.length - 3]
    lastTwoChars[1] = text[text.length - 2]
    lastTwoChars[2] = text[text.length - 1]
  } else {
    return false
  }

  if (
    text.length > 3 &&
    text[text.length - 1] === '.' &&
    text[text.length - 2] === '.' &&
    text[text.length - 3] === '.' &&
    text[text.length - 4] === '.'
  ) {
    return true
  } else if (
    text.length > 2 &&
    lastTwoChars[0] === ' ' &&
    lastTwoChars[1] === ' ' &&
    lastTwoChars[2] === ' ' &&
    lastTwoChars[0] !== '.' &&
    lastTwoChars[1] !== '.' &&
    lastTwoChars[2] !== '.'
  ) {
    return true
  } else if (
    text.length > 2 &&
    lastTwoChars[0] === lastTwoChars[1] &&
    lastTwoChars[1] === lastTwoChars[2] &&
    lastTwoChars[0] !== '.' &&
    lastTwoChars[1] !== '.'
  ) {
    return true
  }
}

export const deleteSpacing = (text) => {
  const fixedText = text.trim()
  return fixedText
}

export const onlyLetters = (text) => {
  const regEx = /^[a-zA-Z ]+$/
  return regEx.test(text)
}

export const onlySpanishLetters = (text) => {
  const regEx = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/
  return regEx.test(text)
}

export const onlyIntegerPositiveNumbers = (text) => {
  const regEx = /^[1-9]\d*$/
  return regEx.test(text)
}

export const fileExtension = (
  e,
  setFileName,
  setFile,
  message,
  setModalMessage,
  handleShow
) => {
  setModalMessage('')

  const files = e.target.files
  const acceptAttributeWithoutSpaces = e.target.accept.replace(/\s/g, '')
  const acceptAttributeArray = acceptAttributeWithoutSpaces.split(',')
  const allowedExtensions = acceptAttributeArray.map((extension) =>
    extension.substring(1)
  )

  const maxSizePerMediaFile = 20971520 // 20971520 Bytes or 20 MB
  const maxSizePerStaticFile = 5242880 // 5242880 Bytes or 5 MB

  const emptyFilesField = (notAllowedSize = false) => {
    setFileName(null)
    setFile(null)

    const filesArray = Array.from(e.target.files)
    filesArray.splice(0, 1)
    const newFileList = new DataTransfer()
    filesArray.forEach((file) => {
      newFileList.items.add(file)
    })
    e.target.files = newFileList.files

    if (notAllowedSize) {
      setModalMessage(
        `${validateFunctionsBuyerString.maxFileExceededPart1} ${(
          size /
          (1024 * 1024)
        ).toFixed(2)} ${validateFunctionsBuyerString.measurementUnits} ${
          validateFunctionsBuyerString.maxFileExceededPart2
        } ${maxSizePerMediaFile / (1024 * 1024)} ${
          validateFunctionsBuyerString.measurementUnits
        } ${validateFunctionsBuyerString.maxFileExceededPart3} ${
          maxSizePerStaticFile / (1024 * 1024)
        } ${validateFunctionsBuyerString.measurementUnits}.`
      )
    } else {
      setModalMessage(
        `${message}${allowedExtensions.map((item) => ' ' + item)}`
      )
    }
    handleShow()
  }

  if (files.length === 0) {
    emptyFilesField()
    return false
  }

  const file = files[0]
  const { type, size } = file
  const fileExtension = type.split('/')[1]
  const fileType = type.split('/')[0]

  if (!allowedExtensions.includes(fileExtension)) {
    emptyFilesField()
    return false
  } else {
    if (fileType.includes('audio') || fileType.includes('video')) {
      if (size > maxSizePerMediaFile) {
        emptyFilesField(true)
        return false
      }
    }
    if (fileType.includes('image') || fileType.includes('application')) {
      if (size > maxSizePerStaticFile) {
        emptyFilesField(true)
        return false
      }
    }
  }

  setFileName(file.name)
  setFile(file)
  return true
}

export const validateNoWhiteSpacesAtBeginning = (text) => {
  if (
    text === ' ' ||
    text === '  ' ||
    text === '  ' ||
    text === '   ' ||
    text === '   '
  ) {
    return false
  }
  const regEx = /^(\s*|[^\s].*)$/
  return regEx.test(text)
}

export const hasRepeatedCharactersAtMiddle = (text) => {
  if (text.length > 0) {
    let firstChar = null
    let secondChar = null
    let thirdChar = null
    let fourthChar = null
    for (let i = 0; i < text.length; i++) {
      firstChar = text[i]
      secondChar = text[i + 1]
      thirdChar = text[i + 2]
      fourthChar = text[i + 3]
      if (
        firstChar === '.' &&
        secondChar === '.' &&
        thirdChar === '.' &&
        fourthChar === '.'
      ) {
        return true
      }
      if (firstChar === ' ' && secondChar === ' ' && thirdChar === ' ') {
        return true
      }
      if (
        firstChar !== '.' &&
        firstChar === secondChar &&
        firstChar === thirdChar
      ) {
        return true
      }
    }
    return false
  }
}

export const validateNoWhiteSpacesAtMiddle = (text) => {
  const regEx = /^[^\s]*$/
  return regEx.test(text)
}

export const validateNames = (text) => {
  const regEx = /^(?:[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ][a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]*)?$/
  return regEx.test(text)
}
