import { useState } from 'react'
import { employeeString } from '../utils/strings'

export const useIncorrectInputFormat = () => {
  const defaultValues = {
    comertialNameFormat: 'employeeRightFormat',
    comertialNameMessage: employeeString.mandatoryField,
    bussinessNameFormat: 'employeeRightFormat',
    bussinessNameMessage: employeeString.mandatoryField,
    nitFormat: 'employeeRightFormat',
    nitMessage: employeeString.mandatoryField,
    cityFormat: 'employeeRightFormat',
    cityMessage: employeeString.mandatoryField,
    countryFormat: 'employeeRightFormat',
    countryMessage: employeeString.mandatoryField,
    documentExpeditionCityFormat: 'employeeRightFormat',
    documentExpeditionCityMessage: employeeString.mandatoryField,
    documentNumberFormat: 'employeeRightFormat',
    documentNumberMessage: employeeString.mandatoryField,
    documentTypeIdFormat: 'employeeRightFormat',
    documentTypeIdMessage: employeeString.mandatoryField,
    idNumFormat: 'employeeRightFormat',
    idNumMessage: employeeString.mandatoryField,
    legalRepresentativeNameFormat: 'employeeRightFormat',
    legalRepresentativeNameMessage: employeeString.mandatoryField,
    contactPersonNameFormat: 'employeeRightFormat',
    contactPersonNameMessage: employeeString.mandatoryField,
    correspondenceAddressFormat: 'employeeRightFormat',
    correspondenceAddressMessage: employeeString.mandatoryField,
    passwordConfirmationFormat: 'employeeRightFormat',
    passwordConfirmationMessage: employeeString.mandatoryField,
    passwordFormat: 'employeeRightFormat',
    passwordMessage: employeeString.mandatoryField,
    notificationNumberFormat: 'employeeRightFormat',
    notificationNumberMessage: employeeString.mandatoryField
  }

  const [incorrectInputFormat, setIncorrectInputFormat] = useState(defaultValues)

  return { incorrectInputFormat, setIncorrectInputFormat }
}
